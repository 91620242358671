/* eslint-disable no-mixed-spaces-and-tabs */
import React, { FC, useMemo } from 'react';
import { CalendarColor } from '@components/Icon';
import Rating from '@containers/ExpertInfo/components/Rating';
import { Body3, Body4, Button, H6, Label1, Label3, Label4 } from '@pixiepkg/react';
import { SCREEN_SIZES, useWindowSize } from '@shared/hooks/useWindownSize';
import { Profile } from '@shared/models/Profile.model';
import { sessionAtom } from '@shared/stores/session.store';
import { getTime } from '@shared/utils/date';
import { getFullName } from '@shared/utils/name';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from './style/ExpertCard.module.scss';
import Skeleton from 'react-loading-skeleton';
import { putCommaMoney } from '@shared/utils/money';
import { isTagLastRow } from './utils';
import { toast } from 'react-toastify';
import { loginModal } from '@shared/stores/loginModal.store';
import { AnimationTag } from './AnimationTag';
import { getTooltipProps } from '@shared/constant/tooltip';
import { NonSSRWrapper } from '@components/NoSSRWrapper';

type Props = {
	expert?: Profile;
	loading?: boolean;
	compactMode?: boolean;
	openNewTab?: boolean;
	onClick?: (id: string) => void;
	onClickBookNow?: (id: string) => void;
	clickedFrom?:
		| 'homepage'
		| 'expert_recommend'
		| 'before_webinar'
		| 'in_webinar'
		| 'after_webinar'
		| 'about'
		| 'about_expert_recommend';
};

export const ExpertCard: FC<Props> = ({
	expert,
	loading,
	compactMode,
	openNewTab,
	onClick,
	onClickBookNow,
	clickedFrom
}) => {
	const router = useRouter();
	const { width } = useWindowSize();
	const [session] = useAtom(sessionAtom);
	const [, setIsOpenLogin] = useAtom(loginModal);
	const { t } = useTranslation('home');
	const handleOnClickExpert = () => expert?.id && onClick && onClick(expert?.id);
	const expertPrice =
		expert?.expert?.prices && expert?.expert?.prices.length > 0
			? expert?.expert?.prices[0].price
			: undefined;

	const Body = width > SCREEN_SIZES.xs ? Body3 : Body4;
	const Label = width > SCREEN_SIZES.xs ? Label3 : Label4;

	// const locale = router.locale;

	const tagsAndDomains = useMemo(() => {
		// domain has 2 types because of BE, (string[] | ExpertDomain[])

		// const _domains: string[] =
		// 	expert?.expert?.domains?.map((_domain) => {
		// 		if (typeof _domain === 'string') {
		// 			return `${_domain}`;
		// 		}
		//
		// 		return locale === 'en' ? `${_domain.domainEN}` : `${_domain.domainVN}`;
		// 	}) ?? [];

		return [...(expert?.expert?.tags?.map((tag) => `${tag}`) || [])];
		// return [..._domains, ...(expert?.expert?.tags?.map((tag) => `${tag}`) || [])];
	}, [expert?.expert?.tags]);

	return (
		<Link
			href={`/experts/${expert?.id}${clickedFrom ? `?from=${clickedFrom}` : ''}`}
			passHref
			legacyBehavior
		>
			<NonSSRWrapper>
				<div
					style={{
						pointerEvents: loading ? 'none' : 'auto',
						height: loading ? 280 : compactMode ? 320 : 340
					}}
					className={styles.cardExpert}
					key={expert?.id}
					onClick={handleOnClickExpert}
				>
					<div className="flex w-full flex-col items-center">
						<div className="ml-4 flex w-full items-center">
							{loading ? (
								<Skeleton count={1} height={110} width={110} borderRadius={10} />
							) : (
								<div className={styles.avatarCard}>
									<Image
										className={styles.avatar}
										src={
											expert?.avatar ||
											'https://pixie-avatars.s3.ap-southeast-1.amazonaws.com/default-avatar.jpeg'
										}
										width={110}
										height={110}
										placeholder="blur"
										blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
										objectFit="cover"
										alt="avatar"
									/>
								</div>
							)}

							<div className="ml-4 flex w-52 flex-col justify-center">
								{loading ? (
									<Skeleton count={1} height={30} width={195} />
								) : (
									<Link
										href={`/experts/${expert?.id}${
											clickedFrom ? `?from=${clickedFrom}` : ''
										}`}
										passHref
										legacyBehavior
									>
										<a
											target={openNewTab ? '_blank' : '_self'}
											onClick={handleOnClickExpert}
										>
											<H6 semiBold className="text-2xl hover:text-brand-700">
												{getFullName(expert?.lastName, expert?.firstName)}
											</H6>
										</a>
									</Link>
								)}

								{loading ? (
									<Skeleton className="mt-1" count={1} height={18} width={195} />
								) : (
									<div
										className="text-2-lines"
										{...getTooltipProps(expert?.expert?.headline || '')}
									>
										<Body>{expert?.expert?.headline || ''}</Body>
									</div>
								)}

								{loading ? (
									<Skeleton className="mt-1" count={1} height={18} width={100} />
								) : (
									<Rating score={expert?.expert?.rating || 4} />
								)}
							</div>
						</div>

						<div className="mt-3 flex h-full items-end">
							<div
								className="flex h-24 flex-wrap justify-center
						 gap-2
						"
							>
								{loading
									? Array.from({ length: 3 }).map((_, index) => (
											<Skeleton
												height={32}
												width={70}
												key={index}
												borderRadius={1000}
											/>
									  ))
									: tagsAndDomains.slice(0, 4).map((key, idx) => {
											return (
												<AnimationTag
													key={`${key}-${idx}`}
													isTagLastRow={isTagLastRow(idx, 4)}
													type={
														expert?.expert?.tags?.includes(key)
															? 'tags'
															: 'domains'
													}
												>
													{key}
												</AnimationTag>
											);
									  })}
								{expert?.expert?.tags && expert.expert.tags.length > 4 && (
									<div
										key={Math.random()}
										className={styles.moreTags}
										{...getTooltipProps(
											tagsAndDomains
												.slice(4, expert?.expert?.tags?.length)
												.map((key) => key)
												.join('<br/>')
										)}
									>
										<div>
											<Label4 className="z-50 text-brand-600">
												+{expert?.expert?.tags?.length - 4}
											</Label4>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>

					{!compactMode &&
						(loading ? (
							<Skeleton count={1} height={18} width={320} />
						) : (
							<div className="w-full">
								<div className="flex items-center">
									<CalendarColor size={20} />
									<Body3 className="ml-2">
										{t('frameExperts.expertCard.available')}
									</Body3>
									<Label className="ml-1">
										{expert?.expert?.nextVacant
											? getTime(
													dayjs.unix(expert?.expert?.nextVacant).toDate()
											  )
											: 'In the future'}
									</Label>
								</div>
								<div className="mt-1 flex w-full flex-wrap items-center justify-center">
									<div className="flex w-full justify-between">
										<div>
											{expert?.expert?.mode === 'donation' ? (
												<>
													<div className="rounded-xl bg-green-500 px-2 py-1">
														<Label4 className="text-center text-white">
															Free Session Extra
														</Label4>
													</div>
													{expertPrice && (
														<div
															className="flex items-center"
															{...getTooltipProps(
																'Giá chưa bao gồm VAT'
															)}
														>
															<Label1>0đ</Label1>
															<Body3 className="ml-2 text-gray-400 line-through">
																{putCommaMoney(expertPrice)}
															</Body3>
														</div>
													)}
												</>
											) : (
												<div
													className="flex items-center"
													{...getTooltipProps('Giá chưa bao gồm VAT')}
												>
													<Label3 className="ml-1 text-gray-500">
														{putCommaMoney(expertPrice)}
													</Label3>
												</div>
											)}
										</div>
										<Button
											disabled={session?.user?.id === expert?.id}
											className="z-10"
											onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
												onClickBookNow &&
													onClickBookNow(expert?.id || 'fake_id');

												if (
													session?.accessToken &&
													session.isFetched &&
													session.isSignedIn
												) {
													router.push(
														`/experts/${expert?.id}/booking${
															clickedFrom
																? `?from=${clickedFrom}`
																: ''
														}`
													);
												} else {
													toast.error('You need to login first!');
													setIsOpenLogin({
														isOpen: true,
														from: 'book_now'
													});
												}
											}}
										>
											{t('frameExperts.expertCard.bookNow')}
										</Button>
									</div>
								</div>
							</div>
						))}
				</div>
			</NonSSRWrapper>
		</Link>
	);
};
