import { StarColor, StarOutline } from '@components/Icon';
import { useMemo, FC } from 'react';

type IRatingProps = {
	score: number;
	size?: number;
};

const Rating: FC<IRatingProps> = ({ score, size }) => {
	const _stars = useMemo(() => {
		const _arr = [];
		for (let i = 1; i <= 5; i++) {
			i <= score ? _arr.push(true) : _arr.push(false);
		}
		return _arr;
	}, [score]);

	return (
		<div className="space-x-0.5">
			{_stars.map((star, index) =>
				star ? (
					<StarColor key={index} size={size} color="#13c2c2" />
				) : (
					<StarOutline key={index} size={size} color="#13c2c2" />
				)
			)}
		</div>
	);
};

export default Rating;
