export const isTagLastRow = (index: number, totalTags: number) => {
	/*
    0 1 -> 1
    2 3 -> 2
    4 5 -> 3
    total: 6

        floor(4 / 2)  + 1 = 2 + 1 = 3 = round(6 / 2)
        floor(5 / 2) + 1 = 3 = round(6 / 2)
    */

	/*
        0 1 -> 1
        2 3 -> 2
        4 _ -> 3
        total: 5

        floor(4 / 2)  + 1 = 2 + 1 = 3 = round(5 / 2)
        floor(5 / 2) + 1 = 3 = round(5 / 2)
    */

	return Math.floor(index / 2) + 1 === Math.round(totalTags / 2);
};
