export const putCommaMoney = (money?: number, suffix = 'VNĐ') => {
	if (money === 0) {
		return `0${suffix}`;
	}

	if (!money) {
		return '';
	}

	return `${money.toLocaleString('it-IT')}${suffix}`;
};
