import { Label4 } from '@pixiepkg/react';
import { getTooltipProps } from '@shared/constant/tooltip';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import styles from './AnimationTag.module.scss';

interface Props {
	type: 'tags' | 'domains';
	isTagLastRow: boolean;
}

export const AnimationTag: React.FC<Props> = ({ isTagLastRow, children }) => {
	const router = useRouter();
	const onClickTag = (e: any) => {
		e?.stopPropagation();

		router.push(`/experts?tags=${children?.toString()}`);
	};

	return (
		<>
			<div>
				<button
					style={{
						maxWidth: isTagLastRow ? 124 : 150
					}}
					className={classNames(
						styles.tag,
						'hover relative max-h-12 rounded-full duration-500 hover:scale-110 hover:shadow-xl'
					)}
					{...getTooltipProps(children?.toString() ?? '')}
					onClick={onClickTag}
				>
					<Label4 className="text-brand-600">{children}</Label4>
				</button>
			</div>
		</>
	);
};
